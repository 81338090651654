import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import "../styles/startseite-text.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"
import { faAdjust } from "@fortawesome/free-solid-svg-icons"
import { startseiteTextContent } from "../content/startseite-text"
import { SettingsContext, actions } from "../contexts/settings"
import CoronaInfo from "../components/corona-info"
import LogoDate12 from "../components/logo-date"



const StartseiteTextPage = () => {
    const [settings, dispatchSettingsAction] = useContext(SettingsContext)
    const content = startseiteTextContent[settings.language]
    const [isInitialLoad, setIsInitialLoad] = useState(true)

    useEffect(
        () => {
            if (!isInitialLoad) {
                console.log(settings)
                localStorage.setItem("isHighContrast", settings.isHighContrast)
                localStorage.setItem("isBigFont", settings.isBigFont)
                localStorage.setItem("language", settings.language)
                return
            }
            const storedIsHighContrast = localStorage.getItem("isHighContrast")
            if (storedIsHighContrast !== null) {
                dispatchSettingsAction(actions.setIsHighContrast(JSON.parse(storedIsHighContrast)))
            }
            const storedIsBigFont = localStorage.getItem("isBigFont")
            if (storedIsBigFont !== null) {
                dispatchSettingsAction(actions.setIsBigFont(JSON.parse(storedIsBigFont)))
            }
            const storedLanguage = localStorage.getItem("language")
            if (storedLanguage !== null) {
                dispatchSettingsAction(actions.changeLanguage(storedLanguage))
            }
            setIsInitialLoad(false)
        },
        [dispatchSettingsAction, isInitialLoad, settings]
    )

    return (
        <CoronaInfo>
        <div className="page-container">
            <div className={`page ${settings.isHighContrast ? "high-contrast" : "normal-contrast"} ${settings.isBigFont ? "big-font" : "normal-font"}`}>
                <div className="banner-side" >

                </div>
                <div className="content">
                    <header>
                        <div>
                            <div className="logo-buttons-startseite-text">
                                <div className="logo-container-startseite-text">
                                    <Link to="/" className="link-fix">
                                        <LogoDate12 />
                                    </Link>
                                </div>
                                <div className="change-buttons">
                                    <div className="change-font-size-container">
                                        <button onClick={() => dispatchSettingsAction(actions.toggleIsBigFont())}>
                                            <img className="change-font-size-button" src="/images/icon-fontsize.svg" alt={content.buttonChangeFontsize} />
                                        </button>
                                    </div>
                                    <div className="high-contrast-button-container">
                                        <button className="contrast-icon" onClick={() => dispatchSettingsAction(actions.toggleIsHighContrast())} aria-label={content.buttonChangeContrast}>
                                            <FontAwesomeIcon icon={faAdjust} />
                                        </button>
                                    </div>
                                </div>
                                <div className="language-buttons">
                                    <button className="button" onClick={() => dispatchSettingsAction(actions.changeLanguage("german"))} aria-label={content.buttonGerman}>
                                        <span className="black-button-simple-german">DEUTSCH</span>
                                        <div className="mint-line"></div>
                                    </button>
                                    <button className="button" onClick={() => dispatchSettingsAction(actions.changeLanguage("english"))} aria-label={content.buttonEnglish}>
                                        <span className="black-button-simple-german">ENGLISH</span>
                                        <div className="mint-line"></div>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div className="white-line"></div>
                                <div className="transparent-line"></div>
                                <div className="white-line"></div>
                            </div>
                        </div>
                    </header>
                    <main>
                        <section className="bilder-vertikal  end-of-site">
                            <div className="pics-up">
                                <Link className="pic-startseite-container" to="/ueber">
                                    <img className="pic-startseite" src="/images/startseite-text/Ueber.jpg" alt={content.altUeber}/>
                                    <p className="startseite-text-ueber">{content.ueber}</p>
                                </Link>
                                <Link className="pic-startseite-container" to="/programm">
                                    <img className="pic-startseite" src="/images/startseite-text/Programm.jpg" alt={content.altProgramm}/>
                                    <p className="startseite-text-programm">{content.programm}</p>
                                </Link>
                                <Link className="pic-startseite-container" to="/projekte">
                                    <img className="pic-startseite" src="/images/startseite-text/Projekte.jpg" alt={content.altProjekte}/>
                                    <p className="startseite-text-projekte">{content.projekte}</p>
                                </Link>
                                <Link className="pic-startseite-container" to="/digitales">
                                    <img className="pic-startseite" src="/images/startseite-text/Digitales.jpg" alt={content.altDigitales}/>
                                    <p className="startseite-text-digitales"> {content.digitales}</p>
                                </Link>
                                <Link className="pic-startseite-container" to="/barrierefreiheit">
                                    <img className="pic-startseite" src="/images/startseite-text/Barrierefreiheit.jpg" alt={content.altBarrierefreiheit}/>
                                    <p className="startseite-text-barrierefreiheit">{content.barrierefreiheit}</p>
                                </Link>
                            </div>
                            <div className="pics-down">
                                <Link className="pic-startseite-container" to="/blog">
                                    <img className="pic-startseite" src="/images/startseite-text/Blog.jpg" alt={content.altBlog}/>
                                    <p className="startseite-text-blog">Blog</p>
                                </Link>
                                <a className="pic-startseite-container" href="https://www.eventbrite.de/o/nocturnal-unrest-33095283589" target="_blank" rel="noopener noreferrer">
                                    <img className="pic-startseite" src="/images/startseite-text/Tickets.jpg" alt={content.altTickets}/>
                                    <p className="startseite-text-tickets"> Tickets</p>
                                </a>
                                <Link className="pic-startseite-container" to="/kooperationen">
                                    <img className="pic-startseite" src="/images/startseite-text/Kooperationen.jpg" alt={content.altKooperationen}/>
                                    <p className="startseite-text-cooperations">{content.kooperationen}</p>
                                </Link>
                                <Link className="pic-startseite-container" to="/hilfe">
                                    <img className="pic-startseite" src="/images/startseite-text/CodeofConduct.jpg" alt={content.altHilfe}/>
                                    <p className="startseite-text-code">{content.codeofconduct}</p>
                                </Link>
                                <Link className="pic-startseite-container" to="/team">
                                    <img className="pic-startseite" src="/images/startseite-text/TeamundKontakt.jpg" alt={content.altTeam}/>
                                    <p className="startseite-text-team">{content.team}</p>
                                </Link>
                            </div>
                        </section>
                    </main>
                    <footer>
                        <div className="footer">
                            <div className="white-line"></div>
                            <div className="icons">
                                <div className="icon">
                                    <a href="https://twitter.com/nocturnalunrest" target="_blank" rel="noopener noreferrer" className="fab" aria-label="Twitter">
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </a>
                                </div>
                                <div className="icon">
                                    <a href="https://www.facebook.com/noufrankfurt/" target="_blank" rel="noopener noreferrer" className="fab" aria-label="Facebook">
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                </div>
                                <div className="icon">
                                    <a href="https://www.instagram.com/nocturnalunrest/" target="_blank" rel="noopener noreferrer" className="fab" aria-label="Instagram">
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                </div>
                            </div>


                            <div className="bottom-links">
                                <div className="bottom-links-single">
                                    <Link to="/impressum" className="link-footer" aria-label="Impressum">{content.legalNotice}</Link>
                                </div>
                                <div className="bottom-links-single">
                                    <a href="https://dlist.server.uni-frankfurt.de/mailman/listinfo/nocturnalunrest" target="_blank" rel="noopener noreferrer" className="link-footer" aria-label="News-Letter">{content.newsLetter}</a>
                                </div>
                            </div>


                            <div className="logos-template">
                                <div className="partner">
                                    <Link className="logo-xbig" to="/kooperationspartner/femphils">
                                        <img className="footer-img" src="/logos/Kooperationspartner/bw/bw_Logo_femphils.png" alt={content.logoFemphils} />
                                    </Link>
                                    <Link className="logo-big" to="/kooperationen/ladiez">
                                        <img className="footer-img" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Ladiez.png" : "/logos/Kooperationspartner/bw/bw_Logo_Ladiez.png"} alt={content.logoLadiez} />
                                    </Link>
                                    <Link className="logo-small" to="/kooperationspartner/mousonturm">
                                        <img className="footer-img" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Mousonturm.png" : "/logos/Kooperationspartner/bw/bw_Logo_Mousonturm.png"} alt={content.logoMousonturm} />
                                    </Link>
                                    <Link className="logo-medium" to="/kooperationspartner/hafen2">
                                        <img className="footer-img" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Hafen2.png" : "/logos/Kooperationspartner/bw/bw_Logo_Hafen2.png"} alt={content.logoHafen2} />
                                    </Link>
                                </div>
                                <div className="logo-stabstelle-container">
                                    <p className="sponsor-startseite-text">{content.sponsor}</p>
                                    <a href="https://frankfurt.de/service-und-rathaus/verwaltung/aemter-und-institutionen/stabsstelle-inklusion" target="_blank" rel="noopener noreferrer" className="logo-stabstelle">
                                        <img className="footer-img" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Stabstelle-Inklusion.png" : "/logos/Kooperationspartner/bw/bw_Logo_Stabstelle-Inklusion.png"} alt={content.logoStabstelle} />
                                    </a>
                                </div>
                            </div>
                        </div>

                    </footer>
                </div>

            </div>
        </div>
        </CoronaInfo>
    )
}
export default StartseiteTextPage