const startseiteTextContent = {
    german: {
        sponsor: "Website gefördert durch",
        buttonGerman: "zu deutscher Sprache wechseln",
        buttonEnglish: "zu englischer Sprache wechseln",
        buttonChangeFontsize: "Schriftgröße ändern",
        buttonChangeContrast: "Kontrast ändern",
        share: "teilen",
        logoFemphils: "Kooperationsseite femphils*",
        logoLadiez: "Kooperationsseite Ladiez e.V.",
        logoMousonturm: "Kooperationsseite Künstlerhaus Mousonturm",
        logoHafen2: "Kooperationsseite Hafen 2",
        logoFonds: "Startseite Fonds Soziokultur",
        logoHmwk: "Startseite Hessisches Ministerium für Wissenschaft und Kultur",
        logoGoetheUni: "Startseite Goethe Universität Frankfurt",
        logoTheater: "Startseite Hessische Theaterakademie",
        logoFrauenreferat: "Startseite Frauenreferat Frankfurt",
        logoStabstelle: "Startseite Stabstelle Inklusion Frankfurt",
        altUeber: "Seite \"Über\"",
        altProgramm: "Seite \"Programm\"",
        altProjekte: "Seite \"Projekte\"",
        altDigitales: "Seite \"Digitales\"",
        altKooperationen: "Seite \"Partner:innen\"",
        altBlog: "Seite \"Blog\"",
        altTickets: "Seite \"Ticketshop Mousonturm\"",
        altBarrierefreiheit: "Seite \"Barrierefreiheit\"",
        altHilfe: "Seite \"Hilfe und Awareness \"",
        altTeam: "Seite \"Team und Kontakt\"",
        ueber: "Über",
        programm: "Programm",
        projekte: "Projekte",
        digitales: "Digitales",
        kooperationen: "Partner:innen",
        barrierefreiheit: "Barrierefreiheit",
        codeofconduct: "Hilfe und Awareness ",
        team: "Team und Kontakt",
        legalNotice: "Impressum",
        newsLetter: "nOus-Letter",
        

    },
    english:  {
        sponsor: "Website funded by",
        buttonGerman: "switch to german language",
        buttonEnglish: "switch to englisch language",
        buttonChangeFontsize: "change font size",
        buttonChangeContrast: "change contrast",
        share: "share",
        logoFemphils: "cooperation page femphils*",
        logoLadiez: "cooperation page Ladiez e.V.",
        logoMousonturm: "cooperation page Künstlerhaus Mousonturm",
        logoHafen2: "cooperation page Hafen 2",
        logoFonds: "home page Fonds Soziokultur",
        logoHmwk: "home page Hessisches Ministerium für Wissenschaft und Kultur",
        logoGoetheUni: "home page Goethe Universität Frankfurt",
        logoTheater: "home page Hessische Theaterakademie",
        logoFrauenreferat: "home page Frauenreferat Frankfurt",
        logoStabstelle: "home page Stabstelle Inklusion Frankfurt",
        altUeber: "page \"About\"",
        altProgramm: "page \"Programme\"",
        altProjekte: "page \"Projects\"",
        altDigitales: "page \"Digital\"",
        altKooperationen: "page \"Partners\"",
        altBlog: "page \"Blog\"",
        altTickets: "page \"ticket shop Mousonturm\"",
        altBarrierefreiheit: "page \"accessibility\"",
        altHilfe: "page \"Help and Awareness \"",
        altTeam: "page \"Team and Contact\"",
        ueber: "About",
        programm: "Programme",
        projekte: "Projects",
        digitales: "Digital",
        kooperationen: "Partners",
        barrierefreiheit: "Accessibility",
        codeofconduct: "Help and Awareness ",
        team: "Team and Contact",
        legalNotice: "Legal Notice",
        newsLetter: "nOus-Letter",

    }
}

export {startseiteTextContent}