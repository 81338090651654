import React from "react"

const  LogoDate = () => (
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1781 84.65">
        <title>Logo_V03-02</title>
        <path d="M91,87.18v11.2h.46a25.32,25.32,0,0,1,10.9-10.19A31.57,31.57,0,0,1,116.06,85q8.86,0,14.55,2.41a21.67,21.67,0,0,1,8.95,6.69,26.17,26.17,0,0,1,4.59,10.43,64.92,64.92,0,0,1,1.32,13.61v49.49h-22.1V122.19q0-10-3.11-14.86t-11.05-4.9q-9,0-13.07,5.37t-4,17.66v42.17H70V87.18Z" transform="translate(-70 -85)"/>
        <path d="M1828.9,85v44.35q0,12.28-4,17.66t-13.07,5.37q-7.93,0-11-4.9t-3.11-14.86V85H201.33a45.8,45.8,0,0,0-17.12,3A36.74,36.74,0,0,0,162.74,110a49.44,49.44,0,0,0-3,17.51,48.8,48.8,0,0,0,3,17.43,37.73,37.73,0,0,0,8.4,13.3,36.66,36.66,0,0,0,13.07,8.48,47,47,0,0,0,17.12,3H1804.94a32.27,32.27,0,0,0,13.7-3.11,24.94,24.94,0,0,0,10.89-10.12h.46v11.21h21V85Z" transform="translate(-70 -85)"/>
        <text y="64" x="1430" font-family="HK Grotesk" font-size="67px" font-weight="300" fill="#ff1cff" text-anchor="middle">
            20.05.-24.05.2021
        </text>
    </svg>
)

export default LogoDate